import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tips-numbers" }
const _hoisted_2 = { class: "tips-numbers" }
const _hoisted_3 = { class: "tips-numbers" }
const _hoisted_4 = { class: "personnel-list" }
const _hoisted_5 = { class: "personnel-box" }
const _hoisted_6 = { class: "bottom-content" }
const _hoisted_7 = { class: "input-list" }
const _hoisted_8 = { class: "list-row" }
const _hoisted_9 = { class: "list-row" }
const _hoisted_10 = { class: "list-row" }

import alertBox from '@/components/alert-box.vue'
import { ref, toRef, toRefs, onMounted, getCurrentInstance } from 'vue'
import { disposableTransposing, disposableTransposingArr, getDispatchList, oneKeyInsteadFactory } from '@/api/long-insure'
import { number } from 'echarts'
import { ElMessage } from 'element-plus'
import { dateFormat } from '../utils/util'


export default /*@__PURE__*/_defineComponent({
  __name: 'chang-factory',
  props: ['renewalManufactory'],
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const workTypeList = ref([] as any[])
const companyDetailId = ref('')
const substitution = ref()
// 定义时间加86400000 的意思加了一天
const day = ref(new Date(new Date().getTime() + 86400000))
const valueOne = dateFormat('YYYY-mm-dd', day.value)
console.log(valueOne, 'valueOne')

// 接收父组件传过来的 数组
const a = __props
// 解构然后用toRefs转换为响应式
const { renewalManufactory } = toRefs(a)

const someData = renewalManufactory.value.filter((item: any, index: number) => index > 9)
console.log(someData, 'someDatasomeData')
console.log(renewalManufactory, '子组件的数据是啥')
const isFactory = ref(false)

// 子组件定义一个发送的关闭事件  事件名称为close
const emit = __emit
const companyDetailList = ref([] as any)
// 工种id
const workTypeId = ref('')
// 服务公司名称
const serveCompanyName = ref('')
const content = ref()
// 小红框提示的弹出窗口
const closeOperate = ref(false)
// 获取派遣单位详细信息
const getCompanyDetailList = async () => {
    const result = await getDispatchList({
        pageNum: 1,
        pageSize: 900,
        status: 1,
        isGetFile: 1,
        searchText: ''
    })
    companyDetailList.value = result.list
}

// 选择派遣单位
const onSelectCompany = () => {
    // this.workTypeList =
    console.log(companyDetailId.value, 'companyDetailIdcompanyDetailIdcompanyDetailId')
    const arr: any = companyDetailList.value.filter(
        (item: {
      serveCompanyId: string | number
      occupationList: any
    }) => {
            if (item.serveCompanyId === companyDetailId.value) {
                return item
            }
        }
    )
    workTypeList.value = arr[0].occupationList
    workTypeId.value = ''
    serveCompanyName.value = arr[0].serveCompanyName
    console.log(workTypeId, 'workTypeIdworkTypeIdworkTypeIdworkTypeId')
}

const closeTips = () => {
    closeOperate.value = true
}
// 传给父组件接口操作。
const close = function () {
    // eslint-disable-next-line vue/no-mutating-props
    emit('close', isFactory.value)
}

// 滚动事件
const instance = getCurrentInstance()
console.log(instance, 'proxyproxy')
onMounted(() => {
    // const scrollEvent = $ref
    // console.log(scrollEvent, 'scrollEventscrollEvent')
    // proxy.$refs.tableScroll.bodyWrapper.addEventListener('scroll', (res: any) => {
    // console.log(tableScroll.value.bodyWrapper, 'res是什么内容！')

})

// console.log(tableScroll, 'tableScrolltableScroll')
const changePicker = (e: any) => {
    console.log(e, 'eeeeeeeeeee的值是啥')
}

const value = ref()

// 在这里增加页数调用接口
const loadMorePerson = (e: any) => {
    console.log(e, 'eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
}

const nextStepEvent = async () => {
    console.log(companyDetailId.value, 'companyDetailId.valuecompanyDetailId.valuecompanyDetailId.value')
    console.log(renewalManufactory.value, '222222222222222222222222222221111111111111')
    if (renewalManufactory.value.length < 1) {
    // $message.error('请添加操作人')
        ElMessage({
            type: 'error',
            message: '请添加操作人！'
        })
        return
    }
    // eslint-disable-next-line eqeqeq
    if (companyDetailId.value === '' || undefined) {
        ElMessage({
            type: 'error',
            message: '请选择派遣单位！'
        })
        return
    }
    if (workTypeId.value === '') {
        ElMessage({
            type: 'error',
            message: '请选择工种！'
        })
        return
    }
    content.value = workTypeList.value.filter((item: any) => item.id === workTypeId.value)
    console.log(content.value, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')

    substitution.value = JSON.parse(JSON.stringify(renewalManufactory.value))
    // 循环拿到一个新的数组
    substitution.value = substitution.value.map((item: any) => {
        item.serveCompanyId = companyDetailId.value
        item.occupationId = workTypeId.value
        item.serveCompanyName = serveCompanyName.value
        item.occupationName = content.value[0].name
        // item.insurStartDay = valueOne
        return item
    })
    console.log(substitution.value, '1111111111111111111111111111')
    console.log(renewalManufactory.value, '22222222222222222222222222222000000000')
    const result = await oneKeyInsteadFactory({
        insurStartDay: valueOne,
        staffList:
    substitution.value
    })
    ElMessage({
        type: 'success',
        message: result.msg
    })
  // eslint-disable-next-line no-unused-expressions
  instance?.proxy?.$Bus.emit('close2', '关闭了吗')
}

// 原文链接：https://blog.csdn.net/Pure_White520/article/details/124683998

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(alertBox, {
      "data-title": "一键换厂",
      isCloseType: false,
      onClose: close
    }, {
      default: _withCtx(() => [
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "current" }, " 当前： ", -1)),
        _createElementVNode("div", {
          class: _normalizeClass(['tips row',closeOperate.value?'displayNo':''])
        }, [
          _createElementVNode("div", null, [
            _cache[4] || (_cache[4] = _createTextVNode(" 当前共")),
            _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(renewalManufactory).length), 1),
            _cache[5] || (_cache[5] = _createTextVNode("人，派遣单位")),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(renewalManufactory).map(
              item => item.serveCompanyName).filter(function (item, index) {
              return _unref(renewalManufactory).map(
                item => item.serveCompanyName).indexOf(item) === index;
            }).length), 1),
            _cache[6] || (_cache[6] = _createTextVNode("个，工种")),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(renewalManufactory).map(
              item => item.occupationName).filter(function (item, index) {
              return _unref(renewalManufactory).map(
                item => item.occupationName).indexOf(item) === index;
            }).length), 1),
            _cache[7] || (_cache[7] = _createTextVNode("个。 "))
          ]),
          _createElementVNode("div", {
            class: "wrong-number",
            onClick: closeTips
          }, " x ")
        ], 2),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_table, {
              data: _unref(renewalManufactory),
              style: {"width":"100%"},
              "header-row-style": { color: '#202536' },
              "row-style": { color: '#5E617D' },
              onSelectionChange: _ctx.handleSelectionChangeOne,
              ref: "tableScroll"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  class: "card-no",
                  prop: "userName,idCardNo",
                  "reserve-selection": "",
                  label: "姓名/身份证"
                }, {
                  default: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(scope.row.userName) + "/" + _toDisplayString(scope.row.idCardNo), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  width: "320px",
                  prop: "serveCompanyName",
                  "reserve-selection": "",
                  label: "被派遣单位"
                }),
                _createVNode(_component_el_table_column, {
                  label: "工种",
                  prop: "occupationName",
                  "reserve-selection": "",
                  width: "140px"
                })
              ]),
              _: 1
            }, 8, ["data", "onSelectionChange"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "change" }, " 更换至： ", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "list-date" }, [
                _createElementVNode("span", { class: "list-characteristic" }, "* "),
                _createTextVNode("生效日期 ")
              ], -1)),
              _createVNode(_component_el_date_picker, {
                disabled: "",
                onChange: changePicker,
                modelValue: _unref(valueOne),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(valueOne) ? (valueOne).value = $event : null)),
                type: "date",
                placeholder: "第二天",
                "prefix-icon": "el-icon-caret-bottom",
                "clear-icon": "true",
                format: "YYYY-MM-DD",
                "value-format": "YYYY-MM-DD"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "list-date" }, [
                _createElementVNode("span", { class: "list-characteristic" }, "* "),
                _createTextVNode("被派遣单位 ")
              ], -1)),
              _createVNode(_component_el_select, {
                modelValue: companyDetailId.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((companyDetailId).value = $event)),
                filterable: "",
                placeholder: "选择派遣单位",
                class: "screen-i search-type",
                "prefix-icon": "el-icon-caret-bottom",
                onClick: getCompanyDetailList,
                onChange: _cache[2] || (_cache[2] = ($event: any) => (onSelectCompany()))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(companyDetailList.value, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      label: item.serveCompanyName,
                      value: item.serveCompanyId
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "list-date" }, [
                _createElementVNode("span", { class: "list-characteristic" }, "* "),
                _createTextVNode("工种 ")
              ], -1)),
              _createVNode(_component_el_select, {
                modelValue: workTypeId.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((workTypeId).value = $event)),
                filterable: "",
                placeholder: "选择工种",
                "prefix-icon": "el-icon-caret-bottom",
                class: "screen-i search-type"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(workTypeList.value, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      label: item.name + '(' + item.level + '类)',
                      value: item.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", { class: "step" }, [
          _createElementVNode("button", {
            class: "next-step",
            onClick: nextStepEvent
          }, " 确定 ")
        ])
      ]),
      _: 1
    })
  ]))
}
}

})