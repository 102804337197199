import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "header block" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "search" }
const _hoisted_4 = { class: "flex.align" }
const _hoisted_5 = { class: "container block block-alone" }
const _hoisted_6 = { class: "table" }
const _hoisted_7 = {
  key: 0,
  class: "rovalsState"
}
const _hoisted_8 = {
  key: 1,
  class: "rovalsState"
}
const _hoisted_9 = {
  key: 2,
  class: "rovalsState"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 1,
  class: "grey"
}
const _hoisted_12 = { class: "record-top" }
const _hoisted_13 = { class: "top-name record-top-i" }
const _hoisted_14 = { class: "rovalsState record-top-i" }
const _hoisted_15 = { class: "top-idcord record-top-i" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_export_button = _resolveComponent("export-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_batchPayroll = _resolveComponent("batchPayroll")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_changeFactory = _resolveComponent("changeFactory")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[11] || (_cache[11] = _createElementVNode("text", null, "保单状态", -1)),
            _createVNode(_component_el_select, {
              modelValue: _ctx.searchType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchType) = $event)),
              class: "screen-i search-type"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchTypeList, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    label: item.label,
                    value: item.value,
                    key: index
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "请输入姓名/身份证/被派遣单位名称",
              class: "input search-input",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchText) = $event)),
              onKeydown: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
            }, null, 544), [
              [_vModelText, _ctx.searchText]
            ]),
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
            }, "搜索"),
            _createElementVNode("button", {
              class: "btn-white",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
            }, "重置")
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_export_button, {
              onExport: _cache[5] || (_cache[5] = ($event: any) => (_ctx.getDerivationRoster()))
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("导出名单")
              ])),
              _: 1
            }),
            _createElementVNode("button", {
              class: "derivation",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.keyInsteadFactory && _ctx.keyInsteadFactory(...args)))
            }, " 一键换厂 ")
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_el_tabs, { "model-value": "detail" }, {
          default: _withCtx(() => [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "header height-right" }, [
              _createElementVNode("div", { class: "" })
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_table, {
                data: _ctx.tableData,
                style: {"width":"100%"},
                ref: "dataTable",
                "header-row-style": { color: '#202536' },
                "row-style": { color: '#5E617D' },
                onSelectionChange: _ctx.handleSelectionChangeOne,
                "row-key": _ctx.rowKeyInit,
                type: "selection"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    type: "selection",
                    width: "55",
                    "reserve-selection": "",
                    selectable: _ctx.checkMaterialStatus
                  }, null, 8, ["selectable"]),
                  _createVNode(_component_el_table_column, {
                    type: "index",
                    label: "序号",
                    width: "100px",
                    "reserve-selection": ""
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: _ctx.policyStatus,
                    label: "状态",
                    width: "100px",
                    class: "dot",
                    "reserve-selection": "",
                    "is-dot": ""
                  }, {
                    default: _withCtx((scoped) => [
                      (scoped.row.policyStatus=='0')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _cache[13] || (_cache[13] = [
                            _createElementVNode("em", { class: "sub" }, null, -1),
                            _createTextVNode("待生效 ")
                          ])))
                        : _createCommentVNode("", true),
                      (scoped.row.policyStatus=='1')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _cache[14] || (_cache[14] = [
                            _createElementVNode("em", { class: "adopt" }, null, -1),
                            _createTextVNode("保障中 ")
                          ])))
                        : _createCommentVNode("", true),
                      (scoped.row.policyStatus=='2')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _cache[15] || (_cache[15] = [
                            _createElementVNode("em", { class: "stay" }, null, -1),
                            _createTextVNode("不在保 ")
                          ])))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["prop"]),
                  _createVNode(_component_el_table_column, {
                    prop: "userName",
                    "reserve-selection": "",
                    label: "姓名",
                    width: "130px"
                  }),
                  _createVNode(_component_el_table_column, {
                    class: "card-no",
                    "reserve-selection": "",
                    prop: "encryptionIdCardNo",
                    label: "身份证"
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "方案类型",
                    "reserve-selection": "",
                    prop: "companyPlanName",
                    width: "140px"
                  }),
                  _createVNode(_component_el_table_column, {
                    width: "320px",
                    prop: "serveCompanyName",
                    label: "被派遣单位"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "occupationName",
                    label: "所属工种",
                    width: "380px"
                  }),
                  _createVNode(_component_el_table_column, { label: "操作" }, {
                    default: _withCtx((scoped) => [
                      (scoped.row.idCardNo)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            class: "link",
                            onClick: ($event: any) => (_ctx.getPeopleRecord(scoped.row.idCardNo))
                          }, " 投保记录 ", 8, _hoisted_10))
                        : (_openBlock(), _createElementBlock("p", _hoisted_11, "暂无操作"))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data", "onSelectionChange", "row-key"]),
              _createVNode(_component_el_pagination, {
                class: "pagination",
                currentPage: _ctx.pageNum,
                "onUpdate:currentPage": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pageNum) = $event)),
                "page-size": _ctx.pageSize,
                "onUpdate:pageSize": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pageSize) = $event)),
                "page-sizes": [10, 30, 50, 100],
                layout: "total, sizes, prev, pager, next, jumper",
                total: _ctx.total,
                onSizeChange: _ctx.handleSizeChange,
                onCurrentChange: _ctx.getPayrollList
              }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
            ])
          ]),
          _: 1
        }),
        (_ctx.isBatchPay)
          ? (_openBlock(), _createBlock(_component_batchPayroll, {
              key: 0,
              onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isBatchPay = false)),
              onComplete: _ctx.getPayrollList,
              isType: "payroll"
            }, null, 8, ["onComplete"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.isDeletePopup,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isDeletePopup) = $event)),
        width: "1400px",
        left: "50%",
        top: "5%"
      }, {
        default: _withCtx(() => [
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "record" }, "投保记录", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.personnelInfo[0].userName), 1),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", {
                class: "decorate",
                style: _normalizeStyle({background:_ctx.personnelInfo[0].policyStatus == 1 ? '#00a854' : _ctx.personnelInfo[0].policyStatus == 2 ? '#FF7E68' : '#1989FA'})
              }, null, 4),
              _createTextVNode(" " + _toDisplayString(_ctx.personnelInfo[0].policyStatus == 1 ? '保障中' : _ctx.personnelInfo[0].policyStatus == 2 ? '不在保' : '待生效'), 1)
            ]),
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.personnelInfo[0].encryptionIdCardNo), 1)
          ]),
          _createVNode(_component_el_table, {
            "header-cell-style": {background:'#F7F8FC'},
            data: _ctx.tableData2,
            style: {"width":"100%"},
            "header-row-style": { color: '#202536' },
            "row-style": { color: '#5E617D' },
            onSelectionChange: _ctx.handleSelectionChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                type: "index",
                label: "序号",
                width: "100px"
              }),
              _createVNode(_component_el_table_column, {
                width: "320px",
                prop: "serveCompanyName",
                label: "被派遣单位"
              }),
              _createVNode(_component_el_table_column, {
                prop: "occupationName",
                label: "工种"
              }),
              _createVNode(_component_el_table_column, {
                label: "方案",
                prop: "companyPlanName"
              }),
              _createVNode(_component_el_table_column, {
                prop: "insurStartTime",
                label: "开始时间"
              }),
              _createVNode(_component_el_table_column, {
                class: "card-no",
                prop: "insurEndTime",
                label: "结束时间"
              }, {
                default: _withCtx((scoped) => [
                  _createTextVNode(_toDisplayString(scoped.row.insurEndTime || '暂无结束时间'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "操作时间",
                prop: "createTime"
              })
            ]),
            _: 1
          }, 8, ["data", "onSelectionChange"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    (_ctx.isFactory)
      ? (_openBlock(), _createBlock(_component_changeFactory, {
          key: 0,
          isFactory: _ctx.isFactory,
          onClose2: _ctx.close2,
          renewalManufactory: _ctx.renewalManufactory
        }, null, 8, ["isFactory", "onClose2", "renewalManufactory"]))
      : _createCommentVNode("", true)
  ], 64))
}